import { combineReducers } from 'redux';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { company } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import { getCompany, CompanyUpdatePayload } from '../api/company';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { requestReducer } from '../helpers';

export interface CompanyState {
    list: RequestState<company[]>;
}

const initialState: CompanyState = {
    list: {
        loading: false,
    },
};

// EzeeAsyncAction
export const list = new EzeeAsyncAction<CompanyState['list'], any, company[]>('company/list', initialState.list, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const update = new EzeeAsyncAction<CompanyState['list'], CompanyUpdatePayload, company>(
    'company/update',
    initialState.list,
    requestReducer<CompanyState['list'], company>(initialState.list)
);

export const companyReducer = combineReducers<CompanyState>({
    list: list.reducer,
});

export function* companySaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(getCompany, list));
}

export const getCompanyListState = (state: MainReducerState) => state.company.list;
