import React, { FC, useEffect, useState } from 'react';

// Antd lib
import { Checkbox, Form, Input, message, Modal, Select } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';

// Store
import { useSelector } from 'react-redux';
import {
    create as applicationClientCreateApiCall,
    getApplicationsClientsCreateState,
} from '../../../store/actions/applicationsClients';

// Api Types
import { Application, DeviceType } from '../../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../../hooks';

// Style
import '../../../assets/styles/ApplicationsModal.less';

interface ApplicationClientCreateModalProps {
    applicationId: Application['id'];

    hideComponent: () => void;
    isComponentVisible: boolean;
}

const ApplicationClientCreateModal: FC<ApplicationClientCreateModalProps> = ({
    applicationId,

    hideComponent,
    isComponentVisible,
}) => {
    const createApplicationsClients = useActions(applicationClientCreateApiCall.trigger);
    const createApplicationsClientsState = useSelector(getApplicationsClientsCreateState);
    const [applicationClientCreationForm] = Form.useForm();

    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(isComponentVisible);
    const [application, setApplication] = useState<Application['id']>('');

    const optionsDeviceType = Object.values(DeviceType).map((deviceType) => {
        return (
            <Select.Option key={deviceType} value={deviceType}>
                {deviceType}
            </Select.Option>
        );
    });

    const previous = usePrevious({
        createApplicationsClientsState,
    });

    const onOk = () => {
        applicationClientCreationForm.submit();
    };

    const onCancel = () => {
        setIsVisibleModal(false);
        hideComponent();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        const body = {
            name: values.name,
            reference: values.reference,
            isPrivate: values.isPrivate ?? false,
            type: values.type,
            options: {
                isBackoffice: values.isBackoffice,
            },
            application,
        };
        if (values.type === DeviceType.ios) {
            const iosOptionsValues = {
                authentificationPush: values.authentificationPush,
                keyId: values.keyId,
                teamId: values.teamId,
                bundleId: values.bundleId,
                stagingServer: values.stagingServer ?? false,
            };
            createApplicationsClients({
                body: {
                    ...body,
                    iosOptions: iosOptionsValues,
                },
            });
        } else if (values.type === DeviceType.android) {
            const androidOptionsValues = {
                certificatPush: values.certificatPush,
                bundleId: values.bundleId,
            };
            createApplicationsClients({
                body: {
                    ...body,
                    androidOptions: androidOptionsValues,
                },
            });
        } else {
            createApplicationsClients({
                body,
            });
        }
        setIsVisibleModal(false);
        hideComponent();
    };

    // Use Effect action
    // Application Client Creation State
    useEffect(() => {
        if (previous?.createApplicationsClientsState.loading && !createApplicationsClientsState.loading) {
            if (createApplicationsClientsState.success) {
                message.success("L'application client a été créée");
            }
        }
    }, [previous, createApplicationsClientsState]);

    // Laod application modal in startup
    useEffect(() => {
        applicationClientCreationForm.resetFields();
        setIsVisibleModal(isComponentVisible);
        setApplication(applicationId);
    }, [isComponentVisible, setIsVisibleModal, applicationId, applicationClientCreationForm]);

    // Style
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    // Device Type options
    const shouldUpdate: FormItemProps['shouldUpdate'] = (prevValues, currentValues) => {
        return prevValues.type !== currentValues.type;
    };

    const iosOptions = (
        <>
            <Form.Item
                key={`form-item-authentificationPush`}
                name="authentificationPush"
                label="Clé d'authenfication de push Apple"
                rules={[{ required: true }]}
            >
                <Input.TextArea key={`form-item-authentificationPush-input`} rows={4} placeholder="Ex:" />
            </Form.Item>

            <Form.Item key={`form-item-keyId`} name="keyId" label="Key ID de Push Apple" rules={[{ required: true }]}>
                <Input key={`form-item-keyId-input`} />
            </Form.Item>

            <Form.Item
                key={`form-item-teamId`}
                name="teamId"
                label="Team ID de Push Apple"
                rules={[{ required: true }]}
            >
                <Input key={`form-item-teamId-input`} />
            </Form.Item>

            <Form.Item key={`form-item-bundleId`} name="bundleId" label="Bundle ID iOS" rules={[{ required: true }]}>
                <Input key={`form-item-bundleId-input`} />
            </Form.Item>

            <Form.Item {...tailLayout} key={`form-item-stagingServer`} name="stagingServer" valuePropName="checked">
                <Checkbox key={`form-item-stagingServer-bool`}>Utiliser le serveur de staging Apple</Checkbox>
            </Form.Item>
        </>
    );

    const androidOptions = (
        <>
            <Form.Item
                key={`form-item-certificatPush`}
                name="certificatPush"
                label="Certificat de remote push Android"
                rules={[{ required: true }]}
            >
                <Input.TextArea key={`form-item-certificatPush-input`} rows={4} placeholder="Ex:" />
            </Form.Item>

            <Form.Item
                key={`form-item-bundleId`}
                name="bundleId"
                label="Bundle ID Android"
                rules={[{ required: true }]}
            >
                <Input key={`form-item-bundleId-input`} />
            </Form.Item>
        </>
    );

    return (
        <>
            <Modal
                title="Création d'une application client"
                centered
                visible={isVisibleModal}
                okText="Créer"
                cancelText="Annuler"
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={true}
                width="900px"
            >
                <Form name="Création" form={applicationClientCreationForm} onFinish={onSubmit} {...layout}>
                    <Form.Item
                        label="Nom de l'application client"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom de l'application !" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Référence"
                        name="reference"
                        rules={[{ required: true, message: "Veuillez rentrer la réference de l'application !" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Type d'appareil"
                        name="type"
                        rules={[{ required: true, message: "Veuillez séléctionner le type d'appareil !" }]}
                    >
                        <Select showSearch placeholder="Sélectionner l'appareil" defaultActiveFirstOption={false}>
                            {optionsDeviceType}
                        </Select>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate={shouldUpdate}>
                        {({ getFieldValue }) => {
                            if (getFieldValue('type') === DeviceType.ios) {
                                return iosOptions;
                            } else if (getFieldValue('type') === DeviceType.android) {
                                return androidOptions;
                            } else {
                                return null;
                            }
                        }}
                    </Form.Item>

                    <Form.Item {...tailLayout} name="isPrivate" valuePropName="checked">
                        <Checkbox>Is Private</Checkbox>
                    </Form.Item>

                    <Form.Item {...tailLayout} name="isBackoffice" valuePropName="checked">
                        <Checkbox>Is Back Office</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ApplicationClientCreateModal;
