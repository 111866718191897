import { useEffect, useRef, Ref, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentBreakpointName } from 'react-socks';
import { ActionCreator, bindActionCreators } from 'redux';

export const usePrevious = <T extends unknown>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useShareForwardedRef = <T extends unknown>(forwardedRef: Ref<T>) => {
    // final ref that will share value with forward ref. this is the one we will attach to components
    const innerRef = useRef<T>(null);

    useEffect(() => {
        // after every render - try to share current ref value with forwarded ref
        if (!forwardedRef) {
            return;
        }
        if (typeof forwardedRef === 'function') {
            forwardedRef(innerRef.current);
        } else {
            // @ts-expect-error
            // by default forwardedRef.current is readonly. Let's ignore it
            forwardedRef.current = innerRef.current;
        }
    });

    return innerRef;
};

// Tablet is considered mobile here, customize as needed
export const useIsMobile = () => {
    const breakpoint = useCurrentBreakpointName();

    return breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' || breakpoint === 'lg';
};

export function useActions<A extends ActionCreator<any>>(actionCreators: A, deps?: any[]): A;
export function useActions<A extends Array<ActionCreator<any>>>(actionCreators: A, deps?: any[]): A;
export function useActions(actions: any, deps?: any[]) {
    const dispatch = useDispatch();

    return useMemo(
        () => {
            if (Array.isArray(actions)) {
                return actions.map((a) => bindActionCreators(a, dispatch));
            }
            return bindActionCreators(actions, dispatch);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ? [dispatch, ...deps] : [dispatch]
    );
}

export const useIsMounted = (): (() => boolean) => {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return function cleanup(): void {
            isMounted.current = false;
        };
    }, []);
    const checker = useCallback((): boolean => {
        return isMounted.current;
    }, []);
    return checker;
};
