import api from './_client';

export interface CompanyUpdatePayload {
    id: string;
    body: {
        excluded: boolean;
    };
}

export const getCompany = async () => await api.get('/scraping/company');

export const updateCompany = async (payload: CompanyUpdatePayload) => {
    return await api.put(`/scraping/company/${payload.id}`, payload.body);
};
