import axios from 'axios';

import { constants } from '../../helpers';
import { RequestError } from './';

const client = axios.create({
    baseURL: constants.API.BASE_URL,
    headers: {
        apiKey: constants.API.KEY,
        'x-datalake-api-key': constants.API.KEY,
    },
    withCredentials: true, // automatically send saved cookie
});

client.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const formattedError: RequestError = {
            status: 0,
            message: '',
        };
        if (error.response) {
            formattedError.status = error.response.status;
            formattedError.data = error.response.data;
            formattedError.headers = error.response.headers;

            if (error.response.status === 401) {
                const e = document.createEvent('CustomEvent');
                e.initCustomEvent('unauthorized.error', true, false, 401);
                window.dispatchEvent(e);
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw formattedError;
    }
);

export default client;
