import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { CookiesProvider } from 'react-cookie';
import { ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// locale configuration when not using internationalisation
import fr_FR from 'antd/lib/locale/fr_FR';
import dayjs from 'dayjs';
import dayjsFr from 'dayjs/locale/fr.js';

import './assets/styles/AntOverride.less';

import reportWebVitals from './reportWebVitals';

import { UniversalCookie } from './store/actions/cookies';
import configureStore from './store/configureStore';

import App from './App';

dayjs.locale(dayjsFr);

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <Provider store={store}>
            <CookiesProvider cookies={UniversalCookie}>
                <BrowserRouter>
                    <BreakpointProvider>
                        <ConfigProvider locale={fr_FR}>
                            <Component />
                        </ConfigProvider>
                    </BreakpointProvider>
                </BrowserRouter>
            </CookiesProvider>
        </Provider>,
        document.getElementById('root') as HTMLElement
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
