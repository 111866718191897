import React, { FC, useState, useEffect } from 'react';
import { Form, Input, Button, Tag, FormProps, Spin, Checkbox } from 'antd';
import api from '../../store/api/_client';
import FileDownload from 'js-file-download';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Seo from '../../components/Seo';

interface SpecialScrappingState {
    loading: boolean;
    data: string | null;
}
interface location {
    name: string;
    checked: boolean;
}
interface LocationsState {
    loading: boolean;
    data: location[];
}

const SpecialScrapping: FC = () => {
    const [locationsState, setLocationsState] = useState<LocationsState>({
        loading: false,
        data: [],
    });
    const [specialScrappingState, setSpecialScrappingState] = useState<SpecialScrappingState>({
        loading: false,
        data: null,
    });
    const loadLocation = async () => {
        setLocationsState({ loading: true, data: [] });
        const r: string[] = await api.get('/scraping/location', { params: { distinct: true } });
        setLocationsState({
            loading: false,
            data: r.map((location) => {
                return {
                    name: location,
                    checked: false,
                };
            }),
        });
    };
    useEffect(() => {
        loadLocation();
    }, []);
    const [keywordsArray, setKeywordsArray] = useState<string[]>([]);
    const [keywordField, setKeywordField] = useState<string>('');
    const onFormValidSubmit: FormProps['onFinish'] = async () => {
        setSpecialScrappingState({
            loading: true,
            data: null,
        });
        const r: any = await api.post(
            '/scraping',
            {
                keywords: keywordsArray,
                agency: locationsState.data.filter((loc) => loc.checked).map((loc) => loc.name),
            },
            {
                responseType: 'blob',
            }
        );
        FileDownload(r, 'scraping.csv');
        setSpecialScrappingState({ loading: false, data: r });
    };
    const onTagClose = (index: number) => {
        keywordsArray.splice(index, 1);
        setKeywordsArray(keywordsArray);
    };
    const addKeyword = async () => {
        setKeywordsArray([...keywordsArray, keywordField]);
    };
    const locationOnChange = (e: CheckboxChangeEvent) => {
        const locations: location[] = [...locationsState.data];
        const index: number = locations.findIndex((element) => element.name === e.target.name);
        locations[index].checked = e.target.checked;
        setLocationsState({ ...locationsState, data: locations });
    };
    const lastItem = (scrapingState: SpecialScrappingState, keywordsArray: string[]) => {
        if (scrapingState.loading) {
            return <Spin />;
        }
        return (
            <Button disabled={keywordsArray.length <= 0} htmlType="submit">
                Rechercher
            </Button>
        );
    };
    const checkboxValidation = async () => {
        if (locationsState.data.findIndex((loc) => loc.checked) === -1) {
            throw new Error('You must select at least one agency');
        }
    };
    const keywordInput = (e: React.FormEvent<HTMLInputElement>) => {
        setKeywordField(e.currentTarget.value);
    };
    return (
        <div>
            <Seo title="Scraping" />
            {keywordsArray.map((keyword, index) => {
                return (
                    <Tag closable={true} onClose={(_e) => onTagClose(index)} key={index}>
                        {keyword}
                    </Tag>
                );
            })}
            <Form onFinish={onFormValidSubmit}>
                <Form.Item
                    label="nouveau mot clé"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez renseigner un mot clé',
                        },
                    ]}
                >
                    <Input placeholder="mot clé" value={keywordField} onChange={keywordInput}></Input>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={addKeyword}>
                        Ajouter
                    </Button>
                </Form.Item>
                <Form.Item rules={[{ validator: checkboxValidation }]}>
                    {locationsState.data.map((agency: location, index: number) => {
                        return (
                            <Checkbox
                                name={agency.name}
                                key={index}
                                checked={agency.checked}
                                onChange={locationOnChange}
                            >
                                {agency.name}
                            </Checkbox>
                        );
                    })}
                </Form.Item>
                {lastItem(specialScrappingState, keywordsArray)}
            </Form>
        </div>
    );
};
export default SpecialScrapping;
