import { combineReducers } from 'redux';
import { AuthState, authReducer } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { LogsState, logsReducer } from '../actions/logs';
import { TestState, testReducer } from '../actions/test';
import { UsersState, usersReducer } from '../actions/users';
import { ApplicationsState, applicationsReducer } from '../actions/applications';
import { OrganizationsState, organizationsReducer } from '../actions/organizations';
import { ApplicationsClientsState, applicationsClientsReducer } from '../actions/applicationsClients';
import { CompanyState, companyReducer } from '../actions/company';
import { ScrapLocationState, scrapLocationReducer } from '../actions/scrapLocation';

export interface MainReducerState {
    readonly auth: AuthState;
    readonly cookies: CookiesState;
    readonly logs: LogsState;
    readonly test: TestState;
    readonly users: UsersState;
    readonly applications: ApplicationsState;
    readonly organizations: OrganizationsState;
    readonly applicationsClients: ApplicationsClientsState;
    readonly company: CompanyState;
    readonly scrapLocation: ScrapLocationState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    cookies: cookiesReducer,
    logs: logsReducer,
    test: testReducer,
    users: usersReducer,
    applications: applicationsReducer,
    organizations: organizationsReducer,
    applicationsClients: applicationsClientsReducer,
    company: companyReducer,
    scrapLocation: scrapLocationReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data?: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}
