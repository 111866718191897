import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { logsSaga } from '../actions/logs';
import { usersSaga } from '../actions/users';
import { applicationsSaga } from '../actions/applications';
import { organizationsSaga } from '../actions/organizations';
import { applicationsClientsSaga } from '../actions/applicationsClients';
import { companySaga } from '../actions/company';
import { scrapLocationSaga } from '../actions/scrapLocation';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(logsSaga);
    yield fork(usersSaga);
    yield fork(applicationsSaga);
    yield fork(organizationsSaga);
    yield fork(applicationsClientsSaga);
    yield fork(companySaga);
    yield fork(scrapLocationSaga);
}
