import React, { FC, useEffect, useState } from 'react';

// Antd lib
import { Checkbox, Form, Input, message, Modal, Select } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';

// Store
import { useSelector } from 'react-redux';
import {
    update as updateApplicationsClientAction,
    details as detailsApplicationsClientAction,
    getApplicationsClientsUpdateState,
    getApplicationsClientsDetailsState,
} from '../../../store/actions/applicationsClients';

// Api Types
import { Application, ApplicationClient, DeviceType } from '../../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../../hooks';

// Style
import '../../../assets/styles/ApplicationsModal.less';

interface ApplicationClientUpdateModalProps {
    applicationId: Application['id'];
    applicationClientId: ApplicationClient['id'];

    hideComponent: () => void;
    isComponentVisible: boolean;
}

const ApplicationClientUpdateModal: FC<ApplicationClientUpdateModalProps> = ({
    applicationId,
    applicationClientId,

    hideComponent,
    isComponentVisible,
}) => {
    const [updateApplicationsClients, detailsApplicationsClients] = useActions([
        updateApplicationsClientAction.trigger,
        detailsApplicationsClientAction.trigger,
    ]);
    const udpateApplicationsClientsState = useSelector(getApplicationsClientsUpdateState);
    const detailsApplicationsClientsState = useSelector(getApplicationsClientsDetailsState);
    const [applicationClientUpdateForm] = Form.useForm();

    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(isComponentVisible);
    const [application, setApplication] = useState<Application['id']>('');
    const [applicationClient, setApplicationClient] = useState<ApplicationClient['id']>('');

    const optionsDeviceType = Object.values(DeviceType).map((deviceType) => {
        return (
            <Select.Option key={deviceType} value={deviceType}>
                {deviceType}
            </Select.Option>
        );
    });

    const previous = usePrevious({
        udpateApplicationsClientsState,
        detailsApplicationsClientsState,
    });

    const onOk = () => {
        applicationClientUpdateForm.submit();
    };

    const onCancel = () => {
        setIsVisibleModal(false);
        hideComponent();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        const body = {
            name: values.name,
            isPrivate: values.isPrivate ?? false,
            options: {
                isBackoffice: values.isBackoffice,
            },
        };
        if (values.type === DeviceType.ios) {
            const iosOptionsValues = {
                authentificationPush: values.authentificationPush,
                keyId: values.keyId,
                teamId: values.teamId,
                bundleId: values.bundleId,
                stagingServer: values.stagingServer ?? false,
            };
            updateApplicationsClients({
                id: applicationClient,
                application,
                body: {
                    ...body,
                    iosOptions: iosOptionsValues,
                },
            });
        } else if (values.type === DeviceType.android) {
            const androidOptionsValues = {
                certificatPush: values.certificatPush,
                bundleId: values.bundleId,
            };
            updateApplicationsClients({
                id: applicationClient,
                application,
                body: {
                    ...body,
                    androidOptions: androidOptionsValues,
                },
            });
        } else {
            updateApplicationsClients({
                id: applicationClient,
                application,
                body,
            });
        }
        setIsVisibleModal(false);
        hideComponent();
    };

    // Use Effect action
    // Application Client Update State
    useEffect(() => {
        if (previous?.udpateApplicationsClientsState.loading && !udpateApplicationsClientsState.loading) {
            if (udpateApplicationsClientsState.success) {
                message.success("L'application client a été mis à jour");
            }
        }
    }, [previous, udpateApplicationsClientsState]);

    // Application Client Details State
    useEffect(() => {
        if (previous?.detailsApplicationsClientsState.loading && !detailsApplicationsClientsState.loading) {
            if (detailsApplicationsClientsState.success) {
                applicationClientUpdateForm.setFieldsValue({
                    name: detailsApplicationsClientsState.data?.name ?? '',
                    isPrivate: detailsApplicationsClientsState.data?.isPrivate,
                    isBackoffice: detailsApplicationsClientsState.data?.options.isBackoffice,
                    type: detailsApplicationsClientsState.data?.type,
                    authentificationPush: detailsApplicationsClientsState.data?.iosOptions?.authentificationPush,
                    keyId: detailsApplicationsClientsState.data?.iosOptions?.keyId,
                    teamId: detailsApplicationsClientsState.data?.iosOptions?.teamId,
                    bundleId:
                        detailsApplicationsClientsState.data?.iosOptions?.bundleId ??
                        detailsApplicationsClientsState.data?.androidOptions?.bundleId,
                    stagingServer: detailsApplicationsClientsState.data?.iosOptions?.stagingServer,
                    certificatPush: detailsApplicationsClientsState.data?.androidOptions?.certificatPush,
                });
            }
        }
    }, [previous, applicationClientUpdateForm, detailsApplicationsClientsState]);

    // Laod application modal in startup
    useEffect(() => {
        if (applicationId && applicationClientId) {
            setApplication(applicationId);
            setApplicationClient(applicationClientId);
            detailsApplicationsClients({
                id: applicationClientId,
                application: applicationId,
            });
            setIsVisibleModal(isComponentVisible);
        }
    }, [isComponentVisible, setIsVisibleModal, applicationId, applicationClientId, detailsApplicationsClients]);

    // Style
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    // Device Type options
    const shouldUpdate: FormItemProps['shouldUpdate'] = (prevValues, currentValues) => {
        return prevValues.type !== currentValues.type;
    };

    const iosOptions = (
        <>
            <Form.Item
                key={`form-item-authentificationPush`}
                name="authentificationPush"
                label="Clé d'authenfication de push Apple"
                rules={[{ required: true }]}
            >
                <Input.TextArea key={`form-item-authentificationPush-input`} rows={4} placeholder="Ex:" />
            </Form.Item>

            <Form.Item key={`form-item-keyId`} name="keyId" label="Key ID de Push Apple" rules={[{ required: true }]}>
                <Input key={`form-item-keyId-input`} />
            </Form.Item>

            <Form.Item
                key={`form-item-teamId`}
                name="teamId"
                label="Team ID de Push Apple"
                rules={[{ required: true }]}
            >
                <Input key={`form-item-teamId-input`} />
            </Form.Item>

            <Form.Item key={`form-item-bundleId`} name="bundleId" label="Bundle ID iOS" rules={[{ required: true }]}>
                <Input key={`form-item-bundleId-input`} />
            </Form.Item>

            <Form.Item {...tailLayout} key={`form-item-stagingServer`} name="stagingServer" valuePropName="checked">
                <Checkbox key={`form-item-stagingServer-bool`}>Utiliser le serveur de staging Apple</Checkbox>
            </Form.Item>
        </>
    );

    const androidOptions = (
        <>
            <Form.Item
                key={`form-item-certificatPush`}
                name="certificatPush"
                label="Certificat de remote push Android"
                rules={[{ required: true }]}
            >
                <Input.TextArea key={`form-item-certificatPush-input`} rows={4} placeholder="Ex:" />
            </Form.Item>

            <Form.Item
                key={`form-item-bundleId`}
                name="bundleId"
                label="Bundle ID Android"
                rules={[{ required: true }]}
            >
                <Input key={`form-item-bundleId-input`} />
            </Form.Item>
        </>
    );

    return (
        <>
            <Modal
                title="Modifier une application client"
                centered
                visible={isVisibleModal}
                okText="Modifier"
                cancelText="Annuler"
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={true}
                width="900px"
            >
                <Form name="Modification" form={applicationClientUpdateForm} onFinish={onSubmit} {...layout}>
                    <Form.Item
                        label="Nom de l'application client"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom de l'application !" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Type d'appareil"
                        name="type"
                        rules={[{ required: true, message: "Veuillez séléctionner le type d'appareil !" }]}
                    >
                        <Select
                            disabled
                            showSearch
                            placeholder="Sélectionner l'appareil"
                            defaultActiveFirstOption={false}
                        >
                            {optionsDeviceType}
                        </Select>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate={shouldUpdate}>
                        {({ getFieldValue }) => {
                            if (getFieldValue('type') === DeviceType.ios) {
                                return iosOptions;
                            } else if (getFieldValue('type') === DeviceType.android) {
                                return androidOptions;
                            } else {
                                return null;
                            }
                        }}
                    </Form.Item>

                    <Form.Item {...tailLayout} name="isPrivate" valuePropName="checked">
                        <Checkbox>Is Private</Checkbox>
                    </Form.Item>

                    <Form.Item {...tailLayout} name="isBackoffice" valuePropName="checked">
                        <Checkbox>Is Back Office</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ApplicationClientUpdateModal;
