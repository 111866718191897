import { combineReducers } from 'redux';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { scrapLocation } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import { getScrapLocation } from '../api/scrapLocation';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

export interface ScrapLocationState {
    list: RequestState<scrapLocation[]>;
}

const initialState: ScrapLocationState = {
    list: {
        loading: false,
    },
};

// EzeeAsyncAction
export const list = new EzeeAsyncAction<ScrapLocationState['list'], any, scrapLocation[]>(
    'scrapLocation/list',
    initialState.list,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: payload,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.list,
        }),
    }
);

export const scrapLocationReducer = combineReducers<ScrapLocationState>({
    list: list.reducer,
});

export function* scrapLocationSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(getScrapLocation, list));
}

export const getScrapLocationState = (state: MainReducerState) => state.scrapLocation.list;
