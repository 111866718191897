import React, { FC, useEffect } from 'react';
import { Table, Radio, Input } from 'antd';
import { useActions } from '../../hooks';
import { useSelector } from 'react-redux';
import { list as companyList, getCompanyListState } from '../../store/actions/company';
import { company } from '../../store/api/apiTypes';
import Seo from '../../components/Seo';
import api from '../../store/api/_client';

const { Column } = Table;

type ExcludedProp = {
    record: company;
};

const Excluded: FC<ExcludedProp> = ({ record }) => {
    const [value, setValue] = React.useState(record.excluded);
    const onChange = (e: any) => {
        setValue(e.target.value);
        api.put('/scraping/company/' + record.id, { excluded: e.target.value });
    };
    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={true}>Excluded</Radio>
            <Radio value={false}>Not excluded</Radio>
        </Radio.Group>
    );
};

const Company: FC = () => {
    const [search, setSearch] = React.useState('');
    const [loadCompany] = useActions([companyList.trigger]);

    const companyListState = useSelector(getCompanyListState);

    useEffect(() => {
        loadCompany();
    }, [loadCompany]);

    const filter = () => {
        if (search.length) {
            const filterData = companyListState.data?.filter((company) => company.name.includes(search));
            return filterData;
        }
        return companyListState.data;
    };
    return (
        <div>
            <Seo title="Company" />
            <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}></Input>
            <Table dataSource={filter()}>
                <Column title="Company name" dataIndex="name" key="name" />
                <Column
                    title="Action"
                    key="action"
                    render={(text, record: company) => {
                        return <Excluded record={record} key={record.id} />;
                    }}
                />
            </Table>
        </div>
    );
};
export default Company;
