// Types of the backend API

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    koala = 'koala',
    api = 'api',
    nothing = '',
}

export interface Organization {
    id: string;
    name: string;
    applications?: Application[];
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    organization?: Organization;
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

// Scrapping
export interface job {
    jobName: string;
    location: string;
    jobBoard: string;
    keyword: string;
}

export interface jobData {
    id: string | undefined;
    compagnyName: string;
    jobs: job[];
    insertRun: Date;
}

export interface keyword {
    id: string;
    keyword: string;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;
}
export interface company {
    id: string;
    name: string;
    excluded: boolean;
}

export interface scrapLocation {
    id: string;
    name: string;
    linkedinName: string;
    linkedinGeoId?: number;
    agency: string;
}
