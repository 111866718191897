import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Descriptions, Result, PageHeader } from 'antd';
import { useParams, RouteComponentProps, useHistory } from 'react-router-dom';

import { User } from '../../store/api/apiTypes';
import { details as usersDetails, getUserStateById } from '../../store/actions/users';

import Seo from '../../components/Seo';
import { getFullName } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import CardSkeleton from '../../components/CardSkeleton';
import LayoutHeader from '../../components/LayoutHeader';
import { useActions } from '../../hooks';

interface MatchParams {
    userId: User['id'];
}

const UserDetails: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
    const loadUserDetails = useActions(usersDetails.trigger);
    const userDetailsState = useSelector(getUserStateById(match.params.userId));
    const history = useHistory();
    const { userId } = useParams<{ userId?: string }>();
    const onClickBackButton = () => {
        history.push(getRoute(RoutePathName.usersList), { restoreQueryParams: true });
    };

    useEffect(() => {
        loadUserDetails({ id: userId });
    }, [loadUserDetails, userId]);

    return (
        <>
            <Seo title={getFullName(userDetailsState.data?.firstName, userDetailsState.data?.lastName)} />
            <LayoutHeader>
                <PageHeader
                    onBack={onClickBackButton}
                    title={getFullName(userDetailsState.data?.firstName, userDetailsState.data?.lastName)}
                />
            </LayoutHeader>
            {userDetailsState.loading && <CardSkeleton rows={6} />}
            {!userDetailsState.loading && (
                <Card>
                    {!userDetailsState.error && (
                        <Descriptions column={1} bordered>
                            {Object.entries(userDetailsState.data ?? {}).map(([key, value]) => (
                                <Descriptions.Item label={key} key={key}>
                                    {JSON.stringify(value)}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    )}
                    {userDetailsState.error && <Result status={500} />}
                </Card>
            )}
        </>
    );
};

export default UserDetails;
