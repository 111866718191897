import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import './assets/styles/App.less';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import CookieBanner from './components/CookieBanner';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import Privacy from './pages/privacy';
import UsersList from './pages/usersList';
import { getRawRoute, RoutePathName } from './routes';
import UserDetails from './pages/userDetails';
import ForgottenPassword from './pages/forgottenPassword';
import ResetPassword from './pages/resetPassword';
import Applications from './pages/applications';
import Organizations from './pages/organizations';
import SpecialScraping from './pages/scraping';
import Company from './pages/company';

const App: FC = () => (
    <Switch>
        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
        <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
        <ProtectedRoute>
            <CookieBanner />
            <MainLayout>
                <ErrorBoundary>
                    <Switch>
                        <Route exact path={getRawRoute(RoutePathName.home)}>
                            <Home />
                        </Route>

                        <Route exact path={getRawRoute(RoutePathName.specialScraping)} component={SpecialScraping} />

                        <Route exact path={getRawRoute(RoutePathName.company)} component={Company} />

                        <Route exact path={getRawRoute(RoutePathName.usersDetails)} component={UserDetails} />

                        <Route exact path={getRawRoute(RoutePathName.usersList)}>
                            <UsersList />
                        </Route>

                        <Route exact path={getRawRoute(RoutePathName.organizations)}>
                            <Organizations />
                        </Route>

                        <Route exact path={getRawRoute(RoutePathName.applications)}>
                            <Applications />
                        </Route>

                        <Route exact path={getRawRoute(RoutePathName.privacy)}>
                            <Privacy />
                        </Route>

                        <Route path="*">
                            <ErrorPage />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </MainLayout>
        </ProtectedRoute>
    </Switch>
);

export default App;
